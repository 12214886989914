import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/C_Controller/02_services/authentication.service';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css']
})
export class NavBarComponent implements OnInit {

  isLoggedIn:boolean;
  currentUser = this.authenticationService.currentUser();

  constructor(
    private authenticationService: AuthenticationService
  ) {
    authenticationService.loggedInStatusChanged.subscribe(loggedIn => this.updateStatus(loggedIn));
   }

  ngOnInit() {
    this.isLoggedIn = this.authenticationService.isLoggedIn();
    this.currentUser = this.authenticationService.currentUser();
  }

  private updateStatus(loggedInStatus) {
    this.isLoggedIn = loggedInStatus;
    this.currentUser = this.authenticationService.currentUser();
  }


  logout = function() {
    this.authenticationService.logout();
    return this.router.navigate(['/', 'home']);
  };

}
