import { Injectable, Output, EventEmitter } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';

import { WindowRefService } from './window-ref.service';

import { User, UserDetails, TokenPayload, AdminAccount, HTTPResponse, ClientAccount } from '../../A_Model/01_models/myModels';
import { Router } from '@angular/router';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  @Output() loggedInStatusChanged: EventEmitter<any> = new EventEmitter();  
  @Output() authenticationMessage: EventEmitter<any> = new EventEmitter();  
  @Output() adminListChanged: EventEmitter<any> = new EventEmitter(); 
  @Output() deleteAdminMessage: EventEmitter<any> = new EventEmitter();  
  @Output() clientListChanged: EventEmitter<any> = new EventEmitter(); 
  @Output() deleteClientMessage: EventEmitter<any> = new EventEmitter();  

  private dbUrl = 'https://fluffyresortsbackend.herokuapp.com/api/admin';  // URL to web api
  //private dbUrl = 'http://73.140.253.103:5000/api/admin';  // URL to web api
  private token: string;
  

  constructor(
    private $http: HttpClient,
    private $window: WindowRefService,
    public router: Router) {
    
  }

  private saveToken(token:string): void {
    this.$window.nativeWindow.localStorage['fluffyResorts-token'] = token;
    this.token = token;
    if (this.isLoggedIn) {
      this.loggedInStatusChanged.emit(true);
    }
    else {
      this.loggedInStatusChanged.emit(false);
    }
  }

  private getToken() {
    if (!this.token) {
      this.token = this.$window.nativeWindow.localStorage['fluffyResorts-token'];
    }
    return this.token;
  }

  public logout(){
    this.token = '';
    this.$window.nativeWindow.localStorage.removeItem('fluffyResorts-token');
    if (this.isLoggedIn) {
      this.loggedInStatusChanged.emit(true);
    }
    else {
      this.loggedInStatusChanged.emit(false);
    }
    this.router.navigateByUrl('/');
  };


  public getUserDetails(): UserDetails {
    const token = this.getToken();
    let payload;
    if (token) {
      payload = token.split('.')[1];
      payload = this.$window.nativeWindow.atob(payload);
      return JSON.parse(payload);
    } 
    else {
      return null;
    }
  }

  public isLoggedIn(): boolean {
    const user = this.getUserDetails();
    if (user) {
      return user.exp > Date.now() / 1000;
    } 
    else {
      return false;
    }
  }

  public currentUser() {
    if (this.isLoggedIn()) {
      const token = this.getToken();
      var payload = JSON.parse(this.$window.nativeWindow.atob(token.split('.')[1]));
      return {
        _id: payload._id,
        email: payload.email,
        name: payload.name,
        role: payload.role,
        phone: payload.phone,
        token: token
      };
    }
  }

    /**
   * Http request to api to send email. 
   * 
   * @param message - message to pass to api to be emailed.
   */
  public recoverPassword(message: any): Observable<any> {
    return this.$http.post<any>(`${this.dbUrl}/recoverPassword`, message, httpOptions)
    .pipe(
      tap((response: HTTPResponse) => {
        this.log(`send recovery password=${response.data}`);
        return response.data;
      }),
      catchError(
        this.handleError<any>('recoverPassword'))
    );
  }

  private request(method: 'post'|'get', type: 'login'|'register'|'profile'|'admins'|'clients', user?: TokenPayload): Observable<any> {
    let base;
    switch(method) { 
      case 'post': {  
        base = this.$http.post(`${this.dbUrl}/${type}`, user); 
        break; 
      } 
      case 'get': { 
        base = this.$http.get(`${this.dbUrl}/${type}`, { headers: { Authorization: `Bearer ${this.getToken()}` }}); 
        break; 
      } 
      default: { 
          console.log("Invalid http method choice"); 
          break;              
      } 
    }  
    const request = base.pipe(
      map((response: HTTPResponse) => {
        if (response.data) {
          this.saveToken(response.data);
        }
        return response;
      })
    );  
    return request;
  }
  
  public login(user: TokenPayload): Observable<any> {
    return this.request('post', 'login', user)
    .pipe(
      tap((response) => {
        this.log(`logged in user w/ token=${response.data}`);
        this.saveToken(response.data);
      }),
      catchError(this.handleError<User>('login'))
    );
  }
  
  public profile(): Observable<any> {
    return this.request('get', 'profile')
    .pipe(
      tap((response) => {
        this.log('fetched profile');
        this.saveToken(response.data);
      }),
      catchError(this.handleError<User>('profile'))
    );
  }

  public getAdmins(): Observable<any> {  
    return this.request('get', 'admins')
    .pipe(
      tap((response) => {
        this.log('fetched admins');
      }),
      catchError(this.handleError<User>('getAdmins'))
    );
  }

  public getClients(): Observable<any> {  
    return this.request('get', 'clients')
    .pipe(
      tap((response) => {
        this.log('fetched clients');
      }),
      catchError(this.handleError<User>('getClients'))
    );
  }

  /** POST: add a new item to the server */
  public updateProfile(id, updatePassword, user:User) : Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${this.getToken()}` }),
      params: new  HttpParams().set('updatePassword', updatePassword)
    };
    return this.$http.put<any>(`${this.dbUrl}/user/${id}`, user, httpOptions).pipe(
      tap((response) => {
        this.log(`updated item id=${id} with token : ${response.data}`);
        this.saveToken(response.data);          
      }),
      catchError(this.handleError<any>('updateProfile'))  
    );
  }

  public deleteAdmin(admin:any) : Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${this.getToken()}` })
    };
    return this.$http.post<any>(`${this.dbUrl}/deleteAdmin/${this.currentUser()._id}`, admin, httpOptions)
    .pipe(
      tap((response) => {
        console.log("hello: " + JSON.stringify(response));
        this.log("deleted user");
        this.adminListChanged.emit("updateAdminList");
        return response;
      }),
      catchError(this.handleError<User>('deleteAdmin'))
    );
  }

  public deleteClient(client:any) : Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${this.getToken()}` })
    };
    return this.$http.post<any>(`${this.dbUrl}/deleteClient/${this.currentUser()._id}`, client, httpOptions)
    .pipe(
      tap((response) => {
        console.log("hello: " + JSON.stringify(response));
        this.log("deleted user");
        this.clientListChanged.emit("updateClientList");
        return response;
      }),
      catchError(this.handleError<User>('deleteClient'))
    );
  }
  /**
   * 
   * @param updatePassword 
   * @param user 
   */
  public updateClientUser(updatePassword, user:User) : Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${this.getToken()}` }),
      params: new  HttpParams().set('updatePassword', updatePassword)
    };
    return this.$http.post<any>(`${this.dbUrl}/updateClient/${this.currentUser()._id}`, user, httpOptions)
    .pipe(
      tap((response) => {
        console.log("hello: " + JSON.stringify(response));
        this.log("updated user");
        this.clientListChanged.emit("updateClientList");
      }),
      catchError(this.handleError<User>('updateClient'))
    );
  }

  /**
   * 
   * @param updatePassword 
   * @param user 
   */
  public updateAdminUser(updatePassword, user:User) : Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${this.getToken()}` }),
      params: new  HttpParams().set('updatePassword', updatePassword)
    };
    return this.$http.post<any>(`${this.dbUrl}/updateAdmin/${this.currentUser()._id}`, user, httpOptions)
    .pipe(
      tap((response) => {
        console.log("hello: " + JSON.stringify(response));
        this.log("updated user");
        this.adminListChanged.emit("updateAdminList");
      }),
      catchError(this.handleError<User>('updateAdmin'))
    );
  }

  /**
   * Add new admin to db
   * 
   * @param user new admin detail
   */
  public addAdmin(user: AdminAccount): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${this.getToken()}` })
    };
    return this.$http.post<any>(`${this.dbUrl}/addAdmin/${this.currentUser()._id}`, user, httpOptions)
    .pipe(
      tap((response) => {
        console.log("hello: " + JSON.stringify(response));
        this.log("added user");
        this.adminListChanged.emit("updateAdminList");
      }),
      catchError(this.handleError<User>('addAdmin'))
    );
  }

  public addClient(user: ClientAccount): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${this.getToken()}` })
    };
    return this.$http.post<any>(`${this.dbUrl}/addClient/${this.currentUser()._id}`, user, httpOptions)
    .pipe(
      tap((response) => {
        console.log("hello: " + JSON.stringify(response));
        this.log("added user");
        this.clientListChanged.emit("updateClientList");
      }),
      catchError(this.handleError<User>('addClient'))
    );
  }

  /**
   * Log error message for developer.
   * 
   * @param message - message to print on console
   */
  private log(message: string) {
    console.log("AuthenticationService: " + message);
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T> (operation = 'operation', result?: T) {
    return (response: any): Observable<T> => {
      if (typeof(response.error) == "undefined") {
        console.log("test: " + JSON.stringify(response));
        this.log(`${operation} failed: ${response.message}`);
        this.authenticationMessage.emit("Sorry something went wrong, please try again or contact site admin.");
        return of(result as T);
      }
      var log;
      switch(response.status) {
        case(0) : {
          var mesg = "Server down, please contact site admin at support.fluffyresorts@gmail.com.";
          this.authenticationMessage.emit(mesg);
          this.log(`${operation} failed: ${mesg}${response}`);
          break;
        }
        case(200) : {
          this.authenticationMessage.emit(response.data);
          this.log(`${operation} success with message: ${response.data}`);
          break;
        }
        case(400) : {
          if (response.error.message) {
            log = response.error.message;
          }
          else {
            log = response.error;
          }
          this.authenticationMessage.emit(log);
          this.log(`${operation} failed: ${log}`);
          break;
        }
        case(401) : {
          this.authenticationMessage.emit(response.message);
          this.log(`${operation} failed: ${response.message}`);
          break;
        }
        case(404) : {          
          switch(operation) {
            case("deleteAdmin") : {
              this.deleteAdminMessage.emit(response.error.message);
              break;
            }
            default : {
              this.authenticationMessage.emit(response.error.message);
              break;
            }
          }
          this.log(`${operation} failed: ${response.error.message}`);
          break;
        }
        default: { 
          console.log("Unhandled response status: " + response.status); 
          break;              
       } 
      }
      // TODO: send the error to remote logging infrastructure
      console.error("HandleError: " + JSON.stringify(response)); // log to console instead      

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
