import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CommonModule } from '@angular/common';

//import { HomeComponent } from '../home/home.component';
import { RoutingGuardService } from './routing-guard.service';

const routes: Routes = [  
  { 
    path: 'home', 
    redirectTo: '/dashboard',
    pathMatch: 'full'
    //canActivate: [RoutingGuardService], 
    //component: HomeComponent 
  },
  { 
    path: '', 
    redirectTo: '/dashboard', 
    pathMatch: 'full' 
  },
  {
    path: 'profile',
    canActivate: [RoutingGuardService],
    loadChildren: () => import('src/app/B_View/01_header/03_profile/profile.module').then(mod => mod.ProfileModule)
  },
  {
    path: 'login',
    loadChildren: () => import('src/app/B_View/01_header/02_login/login.module').then(mod => mod.LoginModule)
  },
  {
    path: 'dashboard',
    canActivate: [RoutingGuardService],
    loadChildren: () => import('src/app/B_View/02_body/dashboard/dashboard.module').then(mod => mod.DashboardModule)
  },
  { path: '**', redirectTo: '/dashboard' }  
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

