import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule }    from '@angular/common/http';
import { FormsModule } from '@angular/forms';


import { AppRoutingModule } from './C_Controller/01_routing/app-routing.module';
import { AppComponent } from './app.component';
import { NavBarComponent } from './B_View/01_header/01_nav-bar/nav-bar.component';
import { AuthenticationService } from './C_Controller/02_services/authentication.service';
import { WindowRefService } from './C_Controller/02_services/window-ref.service';
import { PasswordService } from './C_Controller/02_services/password.service';




@NgModule({
  declarations: [
    AppComponent,
    NavBarComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,    
    HttpClientModule,
    FormsModule
  ],
  providers: [
    AuthenticationService,
    WindowRefService,
    PasswordService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }


